<template>
  <div>
    <div>
      <v-card-title v-once class="mt-n5 page-title-sub card-title">
        <v-row style="background-color: #f8f8f8">
          <v-col :cols="10" class="pb-0">
            <h1
              class="mx-12 my-4 page-title-list"
              style="margin-left: 30px !important"
            >
              年会費請求
            </h1>
          </v-col>
        </v-row>
      </v-card-title>
      <div class="mx-10 mt-3">
        <div class="d-flex justify-space-between" style="width:22%">
          <v-select
            :items="years"
            v-model="year"
            dense
            item-text="name"
            item-value="id"
            outlined
          ></v-select>
          <v-select
            :items="listMonth"
            item-text="name"
            v-model="month"
            item-value="id"
            dense
            outlined
            class="ml-2"
          ></v-select>
        </div>
        <template>
          <div class="d-flex mb-3">
            <v-btn
              class="btn-left select"
              tile
              :class="isSelected('Unprocessed')"
              :style="isSelected('Unprocessed') ? 'backgroundColor:#D0D0D0' : ''"
              @click="selectFilter('Unprocessed')"
              large
            >
              未請求
              <div
                v-if="
                  isSelected('Unprocessed') &&
                    clientAnnualfeeListCFAnnual.length > 0
                "
                class="message"
              >
                {{ totalClientAnnualfeeListCFAnnual }}
              </div>
            </v-btn>
            <v-btn
              class="btn-right"
              large
              tile
              @click="selectFilter('Processed')"
              :style="isSelected('Processed') ? 'backgroundColor:#D0D0D0' : ''"
            >
              請求作成済み
            </v-btn>
            <v-btn
              class="btn-right"
              large
              tile
              @click="selectFilter('VsAnnualFee')"
              :style="isSelected('VsAnnualFee') ? 'backgroundColor:#D0D0D0' : ''"
            >
              VS年会費
            </v-btn>
            <v-btn
              class="btn-right"
              large
              tile
              @click="selectFilter('MembershipFee')"
              :style="isSelected('MembershipFee') ? 'backgroundColor:#D0D0D0' : ''"
            >
              メンバーシップフィー
            </v-btn>
          </div>
        </template>
        <v-chip
          color="red"
          label
          text-color="white"
          @click="(visible = 3), (dialogOpen = true)"
          :disabled="selected.length <= 0 || !checkUser"
        >
          選択項目を請求作成する
        </v-chip>
        <Table
          :headers="headers"
          :total="totalClientAnnualfeeListCFAnnual"
          :items="clientAnnualfeeListCFAnnual"
          ref="table"
          :itemsPerPage="30"
          :itemsPerPageOptions="[10, 20, 30, 50, 100, 200, 500]"
          :attr="{
            dense: true,
            'no-data-text': $t('rules.noData'),
            'item-key': 'id',
            class: 'custom-table elevation-1 mt-2',
          }"
          :showSelect="true"
          @setSelected="
            value => {
              selected = value;
            }
          "
          :funReset="reloadData"
          :sortField="listSortField"
        >
          <template v-slot:[`item.status`]="{ item }">
            {{ item.processedAt ? '請求済み' : '未請求' }}
            <span style="color:red">{{ item.hasError ? 'エラー ' : '' }}</span>
          </template>
          <template v-slot:[`item.isCompany`]="{ item }">
            {{ item.client.isCompany ? '法人' : '個人' }}
          </template>
          <template v-slot:[`item.name`]="{ item }">
            {{ item.client.name }}
          </template>
          <template v-slot:[`item.month`]="{ item }">
            {{ item.month }}月
          </template>
          <template v-slot:[`item.vsAnnualFee`]="{ item }">
            ¥{{ item.vsAnnualFee.toLocaleString('ja-JP') }}
          </template>
          <template v-slot:[`item.vsAnnualFeeTax`]="{ item }">
            ¥{{ item.vsAnnualFeeTax.toLocaleString('ja-JP') }}
          </template>
          <template v-slot:[`item.annualFee`]="{ item }">
            ¥{{ item.annualFee.toLocaleString('ja-JP') }}
          </template>
          <template v-slot:[`item.annualFeeTax`]="{ item }">
            ¥{{ item.annualFeeTax.toLocaleString('ja-JP') }}
          </template>
          <template v-slot:[`item.membershipFee`]="{ item }">
            ¥{{ item.membershipFee.toLocaleString('ja-JP') }}
          </template>
          <template v-slot:[`item.membershipFeeTax`]="{ item }">
            ¥{{ item.membershipFeeTax.toLocaleString('ja-JP') }}
          </template>
          <template v-slot:[`item.managementFee`]="{ item }">
            ¥{{ item.managementFee.toLocaleString('ja-JP') }}
          </template>
          <template v-slot:[`item.managementFeeTax`]="{ item }">
            ¥{{ item.managementFeeTax.toLocaleString('ja-JP') }}
          </template>
          <template v-slot:[`item.hotspringFee`]="{ item }">
            ¥{{ item.hotspringFee.toLocaleString('ja-JP') }}
          </template>
          <template v-slot:[`item.hotspringFeeTax`]="{ item }">
            ¥{{ item.hotspringFeeTax.toLocaleString('ja-JP') }}
          </template>
          <template v-slot:[`item.MemberDetails`]="{ item }">
            <v-btn
              class="t-btn--prm"
              icon
              :to="
                '/customer-details/' +
                  item.client.id +
                  '?typeClient=' +
                  item.client.type
              "
              target="_blank"
            >
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.detail`]="{ item }">
            <v-btn
              class="t-btn--prm"
              icon
              :class="{ 'bg--secondary': !checkUser }"
              :disabled="!checkUser"
              @click="(visible = 1), (data = item), setDataChangeMember(item)"
            >
              <v-icon>mdi-pencil</v-icon>
            </v-btn>
          </template>
          <template v-slot:[`item.loginEmail`]="{ item }">
            <span v-if="item.client.memberUser">
              {{ item.client.memberUser.email }}
            </span>
          </template>
        </Table>
      </div>
    </div>

    <!-- Dialog  -->
    <ChangeMember
      v-if="visible === 1"
      :visible="visible === 1"
      ref="form"
      @reloadData="reloadData"
      @close="visible = -1"
      :data="data"
    />

    <v-dialog @click:outside="closeDialog" v-model="dialog">
      <h1 style="color:#212121">年会費請求作成確認</h1>
      <div class="d-flex justify-end mt-2">
        <v-btn
          class="t-btn--prm btn-dialog mr-2"
          style="background-color:#1873D9 !important"
          width="68"
          @click="closeDialog"
        >
          閉じる
        </v-btn>
        <v-btn class="t-btn--red-dark btn-dialog" width="220" @click="save">
          チェックした{{ selected.length }}件を請求作成
        </v-btn>
      </div>
    </v-dialog>
    <!-- <confirmation-of-annual-membership
      :visible="visible === 3"
      @close="visible = -1"
    ></confirmation-of-annual-membership> -->
  </div>
</template>
<script>
import { checkPermissionUserCurrent } from '@/utils/permissions';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import ChangeMember from '../customer/response/detail/MembershipContract/ChangeMember';
import gql from 'graphql-tag';
import { handlErrorView, getCopyErrorTextView } from '@/constants/functions';
import Table from '@/components/Table/index.vue';

export default {
  name: 'PersonalReportTab',
  data() {
    return {
      listSortField: [
        {
          colName: 'status', // === value in headers
          name: 'processedAt',
          fieldName: 'clientAnnualFee',
        },
      ],
      orderBy: null,
      checkUser: checkPermissionUserCurrent(this.$router.currentRoute),
      visible: -1,
      tab: 0,
      data: {},
      selected: [],
      clientID: null,
      dialogOpen: false,
      headers: [
        {
          text: '詳細',
          value: 'detail',
          sortable: false,
        },
        {
          text: '',
          value: 'top',
          align: 'center',
          sortable: false,
        },
        {
          text: 'ステータス',
          value: 'status',
        },
        {
          text: '法人/個人',
          value: 'isCompany',
          sortable: false,
        },
        {
          text: '会員名',
          value: 'name',
          sortable: false,
        },
        {
          text: '会員詳細',
          value: 'MemberDetails',
          align: 'center',
          sortable: false,
        },
        {
          text: '予定請求月',
          align: 'center',
          value: 'month',
          sortable: false,
        },
        {
          text: 'VS年会費',
          value: 'vsAnnualFee',
          sortable: false,
        },
        {
          text: 'VS年会費消費税',
          value: 'vsAnnualFeeTax',
          sortable: false,
        },
        {
          text: '年会費',
          value: 'annualFee',
          sortable: false,
        },
        {
          text: '消費税',
          value: 'annualFeeTax',
          sortable: false,
        },
        {
          text: 'メンバーシップフィー',
          value: 'membershipFee',
          sortable: false,
        },
        {
          text: 'メンバーシップフィー消費税',
          value: 'membershipFeeTax',
          sortable: false,
        },
        {
          text: '管理費',
          value: 'managementFee',
          sortable: false,
        },
        {
          text: '消費税',
          value: 'managementFeeTax',
          sortable: false,
        },
        {
          text: '温泉維持費',
          value: 'hotspringFee',
          sortable: false,
        },
        {
          text: '消費税',
          value: 'hotspringFeeTax',
          sortable: false,
        },
        {
          text: 'ログインアドレス',
          value: 'loginEmail',
          sortable: false,
        },
      ],
      selectedFilter: 'Unprocessed',
    };
  },

  computed: {
    ...mapGetters([
      'totalClientAnnualfeeListCFAnnual',
      'clientAnnualfeeListCFAnnual',
      'yearCFAnnual',
      'monthCFAnnual',
    ]),

    dialog: {
      get() {
        return this.dialogOpen;
      },
      set(value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    year: {
      get() {
        return this.yearCFAnnual;
      },
      set(value) {
        this.setYearCFAnnual(value);
        this.$refs.table.resetPageAndSearch();
      },
    },

    month: {
      get() {
        return this.monthCFAnnual;
      },
      set(value) {
        this.setMonthCFAnnual(value);
        this.$refs.table.resetPageAndSearch();
      },
    },

    years() {
      const arr = [];
      const yearNow = parseInt(new Date().getFullYear());
      const yearStart = yearNow + 10;
      const yearEnd = yearNow - 10;
      for (let i = yearEnd; i <= yearStart; i++) {
        arr.push({ id: i, name: i + '年' });
      }
      return arr;
    },

    listMonth() {
      const arr = [];
      for (let i = 1; i <= 12; i++) {
        arr.push({ id: i, name: i + '月' });
      }
      return arr;
    },
  },

  methods: {
    handlErrorView,
    getCopyErrorTextView,
    ...mapActions(['getClientAnnualfeeListCFAnnual']),

    getContractsId(obj) {
      if (obj !== null) return obj[0].id;
      else return '';
    },

    ...mapMutations([
      'setCopyErrorText',
      'setYearCFAnnual',
      'setMonthCFAnnual',
      'setDataChangeMember',
      'setAlertSuccess',
      'setAlertError',
      'setPermissionUser',
      'setRoleAdminUser',
    ]),

    async reloadData(variables) {
      if (variables) {
        await this.getClientAnnualfeeListCFAnnual({
          paginate: variables.pagination,
          orderBy: variables.orderBy,
          filter: {
            year: this.year,
            month: this.month,
            status: this.selectedFilter,
          },
        });
      }
    },

    closeDialog() {
      this.dialogOpen = false;
      this.$emit('close');
      this.$refs.table.resetPageAndSearch();
    },

    async save() {
      const arr = [];
      this.selected.forEach(e => {
        arr.push(e.id);
      });
      const query = `
            mutation (
              $ids: [Int!]! 
            ) {
              processAnnualFee(ids: $ids) {
                __typename
              }
            }
          `;
      const variables = {
        ids: arr,
      };
      await this.$apollo
        .mutate({
          mutation: gql`
            ${query}
          `,
          variables: variables,
        })
        .then(data => {
          this.setAlertSuccess('作成しました。');
          this.closeDialog();
        })
        .catch(async error => {
          this.setCopyErrorText(
            this.getCopyErrorTextView(query, variables, error.graphQLErrors),
          );
          const errorTmp = await handlErrorView(
            error.graphQLErrors,
            this.setPermissionUser,
            this.setRoleAdminUser,
          );
          if (errorTmp) {
            this.setAlertError(errorTmp, { root: true });
          }
        });
    },
    selectFilter(value) {
      if (this.selectedFilter !== value) {
        this.selectedFilter = value;
        this.$refs.table.resetPageAndSearch();
      }
    },
    isSelected(value) {
      return this.selectedFilter === value;
    },
  },
  mounted() {
    // this.getClientAnnualfeeListCFAnnual(this.paginate)
  },
  components: {
    // ConfirmationOfAnnualMembership,
    ChangeMember,
    Table,
  },
};
</script>

<style lang="scss" scoped>
.v-tab--active {
  background-color: #ffffff !important;
  color: #13ace0 !important;
}

.v-tab--active:hover {
  background-color: #ceeffb !important;
  color: #13ace0 !important;
}

.v-tab {
  background-color: #ffffff;
  color: #aaaaaa;
}

.v-tab:hover {
  background-color: #eeeeee;
  color: #aaaaaa;
}

.btn-icon {
  background-color: #13ace0;
  margin-top: 12px;
}

.v-label {
  font-size: 12px !important;
}
.select {
  background-color: #848484;
}
.message {
  margin-left: 5px;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 6px;
  background-color: red;
  color: white;
}

.text-header-field {
  color: #212121 !important;
}
::v-deep {
  .v-dialog {
    width: 350px;
    height: 120px;
    background-color: white;
    padding: 15px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .v-data-footer__pagination {
    display: none !important;
  }
  .v-input__append-inner {
    .v-icon__svg {
      color: #333333 !important;
    }
  }
}
</style>
